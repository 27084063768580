@import "../../../index.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  .modalContent {
    background-color: white;
    padding: 40px;
    border-radius: 16px;
    width: 550px;
    // width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    position: relative;

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 26px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: $color-primary;
      }
    }
    h2 {
      font-size: 36px;
    }
    p {
      font-size: 30px;
    }
    &__button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      font-size: 22px;
      background-color: #0e0e0e;
      padding: 12px 26px;
      border: 1px solid $color-primary;
      border-radius: 12px;
      cursor: pointer;
      color: $color-primary;
      max-width: 350px;
      transition: all 0.3s ease-in-out;

      margin: 0 auto;
      &:hover {
        background-color: #161616;
        color: $color-white;
        background-color: $color-primary;
      }
      a {
        text-decoration: none;
      }
    }
  }

  @media (max-width: 768px) {
    .modalContent {
      padding: 30px;
      max-width: 60%;
      font-size: 14px;

      .close {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 480px) {
    .modalContent {
      padding: 20px;
      max-width: 93%;
      border-radius: 5px;

      h2 {
        font-size: 22px;
      }
      p {
        font-size: 20px;
      }
      &__button {
        gap: 4px;
        font-size: 16px;
      }
    }
  }

  @media (min-width: 1200px) {
    .modalContent {
      max-width: 600px;
      padding: 50px;
    }
  }
}
