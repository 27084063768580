* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  color: inherit;
}

@font-face {
  font-family: "TT Fors";
  font-weight: 400;
  src: url("./assets/fonts/TypeType - TT Fors Regular.woff2") format("woff2"),
    url("./assets/fonts/TypeType - TT Fors Regular.ttf") format("truetype");
}

@font-face {
  font-family: "TT Fors";
  font-weight: 500;
  src: url("./assets/fonts/TypeType - TT Fors Medium.woff2") format("woff2"),
    url("./assets/fonts/TypeType - TT Fors Medium.ttf") format("truetype");
}

@font-face {
  font-family: "TT Fors";
  font-weight: 600;
  src: url("./assets/fonts/TypeType - TT Fors DemiBold.woff2") format("woff2"),
    url("./assets/fonts/TypeType - TT Fors DemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "TT Fors";
  font-weight: 700;
  src: url("./assets/fonts/TypeType - TT Fors DemiBold.woff2") format("woff2"),
    url("./assets/fonts/TypeType - TT Fors DemiBold.ttf") format("truetype");
}

body {
  font-family: "TT Fors";
  background: #0e0e0e;
}

$color-primary: #ec9f0b;
$color-white: #f5f7f6;

.cookieModal {
  position: fixed;
  bottom: 20px;
  left: 20px;
  max-width: 400px;
  background: #fff;
  color: #000;
  padding: 20px;
  border-radius: 8px;
  z-index: 10000;
  @media (max-width: 480px) {
    width: 100%;
    bottom: 0px;
    left: 0px;
  }
  span {
    display: block;
    font-weight: 600;
  }
}
.cookieButtons {
  display: flex;
  gap: 10px;
}
.cookieButton {
  margin-top: 20px;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
}
.cookieButtonReject {
  margin-top: 20px;
  background: transparent;
  border: 1px solid #000;
  color: #000;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
}
@media (max-width: 480px) {
  button:hover {
    border: 1px solid $color-primary !important;
    background-color: #161616 !important;
    color: $color-primary !important;
  }
}
