@import "../../index.scss";
.secondBlocks {
  background-color: #0e0e0e;
  padding: 50px 20px;
  display: flex;

  flex-direction: column;
  align-items: center;
  gap: 40px;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 20px;
  }

  h2 {
    text-align: center;
    font-size: 46px;
    color: $color-white;
    font-weight: bold;
  }
  &__content {
    max-width: 1160px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 30px;
  }

  .blocks__content__block {
    background-color: $color-white;
    padding: 25px;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: calc(33.33% - 20px);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    @media (max-width: 1024px) {
      justify-content: space-between;

      gap: 6px;
    }

    h3 {
      font-size: 20px;
      color: #161616;
      font-weight: 600;
    }
    img {
      width: 55px;
      height: 55px;
      object-fit: cover;
      @media (max-width: 1024px) {
        width: 40px;
        height: 40px;
      }
    }
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
      @media (max-width: 480px) {
        transform: none;
      }
    }
  }
  .blocks__content__blockWithImage {
    text-align: start;
    @media (max-width: 1024px) {
      text-align: center;
    }
  }
  button {
    margin-top: 10px;
    padding: 20px 40px;
    background-color: #161616;
    color: $color-primary;
    border: 1px solid $color-primary;
    border-radius: 28px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-family: "TT Fors";
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #161616;
      color: $color-white;
      border: 1px solid #161616;
      img {
        transform: rotate(180deg);
      }
    }
    img {
      transition: transform 0.5s ease;
      width: 25px;
      height: 25px;
    }
  }
}

@media (max-width: 768px) {
  .secondBlocks {
    h2 {
      font-size: 28px;
      margin: 0;
    }
    &__content {
      display: flex;
      flex-wrap: wrap;
      .blocks__content__block {
        width: 47%;
        padding: 15px;
        h3 {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .secondBlocks {
    gap: 20px;
    margin: 30px 0px;
    padding: 20px;

    h2 {
      font-size: 30px;
      margin: 0;
    }
    &__content {
      gap: 20px;
      display: flex;
      flex-wrap: wrap;
      .blocks__content__block {
        width: 100%;
        padding: 15px;
        img {
          width: 35px;
          height: 35px;
        }
        h3 {
          font-size: 18px;
          text-align: start !important;
        }
      }
    }
    button {
      padding: 16px 25px;
      font-size: 16px;
      gap: 4px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}
