@import "../../index.scss";
.hero {
  padding: 20px;
  display: flex;
  justify-content: center;
  position: relative;

  align-items: center;
  background-color: #0e0e0e;

  @media (max-width: 480px) {
    height: 100vh;
  }
  &__bgImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  &__bgVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    background: #000;
  }
  &__content {
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    height: 100vh;
    margin: 0 auto;

    &__leftBlock {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;

      &__logo {
        // width: 250px;
        height: 70px;
        object-fit: cover;
      }

      h2 {
        font-size: 44px;
        margin: 0;
        color: $color-white;
        font-weight: 700;
      }

      h4 {
        font-size: 18px;
        color: $color-primary;
      }

      span {
        font-size: 26px;
        color: $color-white;
        font-weight: 500;
      }

      button {
        margin-top: 10px;
        padding: 20px 40px;
        background-color: #161616;
        color: $color-primary;
        border: 1px solid $color-primary;
        border-radius: 28px;
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-family: "TT Fors";
        font-weight: 600;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: #161616;
          color: $color-white;
          border: 1px solid #161616;
          img {
            transform: rotate(180deg);
          }
        }
        img {
          transition: transform 0.5s ease;
          width: 25px;
          height: 25px;
          @media (max-width: 480px) {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &__rightBlock {
      img {
        width: 500px;
        height: auto;
      }
    }
  }

  @media (max-width: 1024px) {
    &__content {
      height: auto;
      &__leftBlock {
        align-items: flex-start;

        h2 {
          font-size: 36px;
        }

        h4 {
          font-size: 16px;
        }

        span {
          font-size: 22px;
        }

        button {
          padding: 15px 20px;
        }
      }
      &__rightBlock {
        img {
          width: 400px;
          height: auto;
          margin-top: 20px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    &__content {
      padding: 20px;
      &__leftBlock {
        h2 {
          font-size: 28px;
        }

        h4 {
          font-size: 14px;
        }

        span {
          font-size: 18px;
        }

        button {
          padding: 14px 20px;
          font-size: 14px;
        }
      }
      &__rightBlock {
        display: none;
      }
    }
  }

  @media (max-width: 480px) {
    .hero {
      padding: 10px;

      &__content {
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: auto;
        margin: 0;
        &__leftBlock {
          gap: 15px;
          &__logo {
            width: 300px;
            height: auto;
          }

          h2 {
            font-size: 30px;
          }

          h4 {
            font-size: 22px;
          }

          span {
            font-size: 24px;
          }

          button {
            padding: 16px 20px;
            font-size: 18px;
            width: 100%;
            justify-content: center;
            text-align: center;
            img {
              width: 25px;
              height: 25px;
            }
          }
        }
        &__rightBlock {
          display: none;
        }
      }
    }

    .hero__content__rightBlock {
      display: none !important;
    }
  }
}
