@import "../../../index.scss";

form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 370px;
  border-radius: 16px;
  padding: 24px;
  background: #f5f7f6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  & > div {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
    gap: 20px;
  }
  .errorMessage {
    text-align: start;
    color: red;
    font-size: 14px;
  }
  .errorInput {
    border: 1px solid red;
    border-radius: 12px;
    z-index: 100;
  }

  p {
    font-weight: 700;
    font-size: 30px;
    text-align: center;
  }

  .phoneInputContainer {
    position: relative;
  }
  .phoneInputWrapper {
    position: relative;
    padding: 0px;
    border: none;
    border-radius: 12px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
  }
  .phoneInput {
    border: none;
    margin-right: 30px;
    padding: 0;
  }
  input {
    padding: 16px 18px;
    border: none;
    border-radius: 12px;
    font-size: 14px;
    border: 1px solid #fff;
    color: #000;
    background: #fff;
    box-shadow: 0 6px 7px rgba(0, 0, 0, 0.1);

    font-family: "TT Fors", sans-serif;

    transition: border-color 0.3s ease;

    &:focus {
      outline: none;
      border: 1px solid #f1f1f1;
      border-color: #f1f1f1;
    }

    &::placeholder {
      color: #777676;
      font-family: "TT Fors", sans-serif;
    }
  }

  .phoneInputWrapper {
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 0 10px;
    background: #fff;
    box-shadow: 0 6px 7px rgba(0, 0, 0, 0.1);

    transition: border-color 0.3s ease;
    & > .phoneInput {
      border: none;
    }
    &:focus-within {
      border-color: #f1f1f1;
    }
  }

  .phonePrefix {
    display: flex;
    align-items: center;
    margin-right: 8px;
    font-weight: bold;
  }

  .flagIcon {
    width: 20px;
    height: 14px;
    margin-right: 8px;
  }

  .phoneInput {
    border: none;
    outline: none;

    flex-grow: 1;
    & > input {
      box-shadow: none;
      border: none;
    }
    @media (max-width: 768px) {
      margin-right: 80px;
    }
    @media (max-width: 480px) {
      margin-right: auto;
    }
  }

  button {
    padding: 16px 24px;
    border: none;
    border-radius: 12px;
    background-color: #161616;
    color: $color-primary;
    border: 1px solid $color-primary;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: "TT Fors";
    justify-content: center;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    gap: 8px;
    &:hover {
      background-color: #161616;
      color: $color-white;
      border: 1px solid #161616;

      img {
        transform: rotate(180deg);
      }
    }
  }
  img {
    transition: transform 0.5s ease;
  }
}

.disclaimer {
  display: block;
  font-size: 13px;
  color: #000;
  margin-top: 6px;

  & > span {
    font-weight: 600;
    color: #000;
  }
  & > p {
    display: block;
    color: #000;
    font-weight: 500;
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  form {
    padding: 16px;

    p {
      font-size: 20px;
    }
    input {
      font-size: 14px;
      padding: 16px;
    }

    button {
      padding: 16px 20px;
      font-size: 14px;
    }
    .disclaimer {
      display: block;
      font-size: 15px;
      color: #000;
      margin-top: 6px;
    }
  }
}

@media (max-width: 480px) {
  form {
    width: 100%;
    padding: 12px;
    .errorMessage {
      font-size: 12px;
      margin-left: 4px;
      margin-top: 2px;
    }
    .phonePrefix {
      font-size: 12px;
    }
    p {
      font-size: 22px;
    }
    .phoneInput {
      width: 100%;
    }
    input {
      font-size: 18px;
      padding: 16px;
    }
    button {
      padding: 16px 20px;
      font-size: 18px;
    }

    .disclaimer {
      margin-top: 0px;
      font-size: 12px;

      & > span {
        font-weight: 600;
        color: #000;
      }
      & > span:nth-child(2) {
        color: #000;
        font-weight: 500;
        font-size: 11px;
        text-decoration: underline;
      }
    }
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loadingIcon {
    animation: rotate 1s linear infinite;
  }
}
