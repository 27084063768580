@import "../../index.scss";

.formSection {
  display: flex;
  justify-content: space-between;
  padding: 50px 20px 50px 20px;
  background-color: #0e0e0e;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 20px;
  }

  position: relative;
  &__content {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1024px) {
      width: 90%;
      flex-direction: column;
      align-items: center;
    }
  }

  &__content__left {
    display: flex;
    flex-direction: column;
    gap: 50px;

    @media (max-width: 480px) {
      gap: 30px;
    }
    &__item {
      @media (max-width: 780px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &__text {
      margin-top: 10px;
      &__link {
        text-decoration: none !important;
      }
    }
    h2 {
      font-size: 48px;
      color: $color-primary;
      font-weight: bold;
      @media (max-width: 780px) {
        font-size: 32px;
      }
      @media (max-width: 480px) {
        font-size: 30px;
      }
    }

    h4 {
      font-size: 20px;
      font-weight: 400;
      color: #f5f7f6;

      @media (max-width: 480px) {
        font-size: 20px;
      }
    }

    p {
      font-size: 22px;
      font-weight: 500;
      color: #f5f7f6;

      @media (max-width: 480px) {
        font-size: 20px;
      }
      &:hover {
        transition: all 0.3s ease;
        cursor: pointer;
        color: $color-primary;
      }
    }
    &__links {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      @media (max-width: 480px) {
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    &__link {
      display: flex;
      margin-top: 10px;
      width: 150px;
      justify-content: center;
      gap: 16px;
      flex-direction: column;

      @media (max-width: 480px) {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
      h4 {
        font-size: 22px;
        font-weight: 400;
        color: #9da79a;
      }
      a {
        font-size: 22px;
        font-weight: 500;
        color: #f5f7f6;
        display: block;

        text-decoration: none;
        transition: color 0.3s ease;
        text-transform: capitalize;
      }

      a:hover {
        cursor: pointer;
        color: $color-primary;
      }
    }
  }

  &__content__right {
    &__calendarblock {
      max-width: 370px;

      @media (max-width: 1024px) {
        width: 370px;
      }

      @media (max-width: 480px) {
        max-width: 100%;
      }
      display: flex;
      align-items: flex-start;
      padding: 16px;
      background: #f5f7f6;

      border-radius: 16px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      gap: 32px;

      .calendar {
        text-align: center;
        border-radius: 8px;

        background: #fff;
        box-shadow: 0 6px 7px rgba(0, 0, 0, 0.1);
        width: 80px;
        @media (max-width: 480px) {
          width: 70px;
        }
        .month {
          font-size: 12px;
          font-weight: 600;
          color: $color-white;
          text-transform: capitalize;
          padding: 4px 2px;
          border-radius: 8px 8px 0px 0;
          background: #e84136;
        }

        .date {
          font-size: 26px;
          font-weight: bold;
          color: #0e0e0e;
        }
        .dayOfWeek {
          font-size: 10px;
          font-weight: bold;
          color: #0077b5;
        }
      }

      .formSection__content__right__eventBlock {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        span {
          font-size: 16px;
          color: #0e0e0e;
          text-align: start;
          text-transform: uppercase;
          font-weight: bold;
        }

        h3 {
          font-size: 24px;
          color: #0e0e0e;

          @media (max-width: 480px) {
            font-size: 18px;
            text-align: start;
          }
        }

        div {
          display: flex;
          align-items: center;
          gap: 8px;
          span {
            font-weight: bold;
            color: #0e0e0e;
          }
        }

        button {
          color: rgb(26, 115, 232);
          background: none;
          outline: none;
          border: none;
          display: flex;
          justify-content: flex-start;
          cursor: pointer;
          font-size: 16px;
          font-weight: 600;
          padding: 0;
          margin-top: 12px;
          transition: all 0.3s ease;
          @media (max-width: 480px) {
            font-size: 12px;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .formSection__content__right__timer {
    display: flex;
    margin-top: 20px;
    max-width: 370px;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    background: #f5f7f6;
    box-shadow: 0 4px 6px rgba(10, 10, 0, 1);
    padding: 12px;
    border-radius: 16px;

    @media (max-width: 480px) {
      gap: 26px;
      justify-content: center;
      max-width: 100%;
    }
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 60px;
      justify-content: center;
      @media (max-width: 480px) {
        width: 40px;
      }
      & > div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-size: 32px;
        font-weight: bold;
        box-shadow: 0 6px 7px rgba(0, 0, 0, 0.1);
        width: 60px;
        color: #0e0e0e;
        background: #fff;
        border-radius: 6px;
        padding: 8px 12px;
        @media (max-width: 768px) {
          width: 45px;
          height: 45px;
        }
        @media (max-width: 480px) {
          font-size: 22px;
        }
      }

      span {
        font-size: 16px;
        color: #0e0e0e;

        @media (max-width: 480px) {
          font-size: 16px;
        }
      }
    }
  }
  &__arrow {
    background: rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 20px;
    padding: 10px;
    border-radius: 50%;
    z-index: 10000;
  }
}

@media screen and (max-width: 1024px) {
  .formSection__content__right__calendarblock {
    width: 370px;
  }
  .formSection__content__left,
  .formSection__content__right {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .formSection__content__right__calendarblock,
  .formSection__content__right__timer {
    margin-top: 15px;
  }

  .formSection__content__right__timer {
    width: 100%;
  }
  .formSection__content__left__link {
    gap: 12px;
  }
}

@media screen and (max-width: 480px) {
  form {
    width: 100%;
  }
  .formSection__content__left {
    align-items: start;
  }
  .formSection__content__left__link {
    gap: 20px;
    & > a {
      font-size: 20px;
    }
  }

  .formSection__content__right__calendarblock {
    width: 100%;
    padding: 16px;
    max-width: 100%;
  }
}
