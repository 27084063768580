@import "../../index.scss";
.blocks {
  background-color: #0e0e0e;
  padding: 50px 20px;
  @media (max-width: 768px) {
    padding: 20px;
  }

  @media (max-width: 480px) {
    padding: 20px;
  }

  &__content {
    max-width: 1160px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 45px;

    @media (max-width: 480px) {
      padding: 0;
      padding-bottom: 20px;
    }
    & > h2 {
      font-size: 46px;
      color: $color-white;
      margin-bottom: 30px;
      font-weight: bold;
      @media (max-width: 768px) {
        padding: 0;
      }
      @media (max-width: 480px) {
        margin-bottom: 20px;
        padding-top: 0px;
      }
    }

    &__block {
      display: flex;
      gap: 30px;
      justify-items: center;
      width: 100%;
      justify-content: space-between;
      &__item {
        background-color: #33393e;
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: space-between;
        padding: 30px;
        border-radius: 20px;
        width: 100%;
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        img {
          width: 80px;
          @media (max-width: 480px) {
            width: 30px;
          }
        }
        h4 {
          font-size: 20px;
          color: $color-white;
          font-weight: 500;

          @media (max-width: 1024px) {
            font-size: 18px;
          }
        }

        &:hover {
          transform: translateY(-10px);
          box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
          @media (max-width: 480px) {
            transform: none;
          }
        }
      }
    }
    button {
      margin-top: 50px;
      padding: 20px 40px;
      background-color: #161616;
      color: $color-primary;
      border: 1px solid $color-primary;
      border-radius: 28px;
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      font-family: "TT Fors";
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: #161616;
        color: $color-white;
        border: 1px solid #161616;
        img {
          transform: rotate(180deg);
        }
      }
      img {
        transition: transform 0.5s ease;
        width: 25px;
        height: 25px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .blocks {
    &__content {
      padding: 0;
      &__block {
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;

        &__item {
          padding: 25px;
          & > h4 {
            text-align: start;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .blocks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .blocks__content h2 {
    font-size: 28px;
  }

  .blocks__content__block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
  }

  .blocks__content__block__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > img {
      width: 40px;
    }
    // h4 {
    //   font-size: 14px;
    // }
  }
}

@media (max-width: 480px) {
  .blocks__content {
    margin: 30px 0;
    button {
      margin-top: 30px;
      padding: 16px 25px;
      font-size: 16px;
      gap: 4px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
  .blocks__content h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .blocks__content_block__item {
    padding: 10px;
    gap: 8px;
    text-align: start;
    align-items: flex-start;
    justify-content: flex-start;

    & > img {
      width: 20px;
      height: 20px;
    }
    h4 {
      font-size: 18px;
      text-align: start;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
}
