@import "../../index.scss";
.aboutMe {
  padding: 50px 20px;
  background-color: #0e0e0e;
  text-align: center;
  @media (max-width: 768px) {
    padding: 20px;
  }

  h2 {
    font-size: 46px;
    color: $color-white;
    font-weight: 600;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      font-size: 30px;
      margin-bottom: 20px;
    }

    @media (max-width: 480px) {
      font-size: 30px;
      margin-bottom: 20px;
      padding: 0px;
    }
  }
  .aboutMe__content__left__links__link svg path {
    fill: $color-white;
    transition: fill 0.3s ease;
  }

  .aboutMe__content__left__links__link:hover svg path {
    fill: $color-primary;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 50px;
    border-radius: 26px;
    max-width: 1160px;
    margin: 0 auto;
    background: rgb(51, 57, 62);
    justify-content: space-between;

    @media (max-width: 768px) {
      padding: 15px;
    }
    @media (max-width: 480px) {
      flex-direction: column;
    }
  }
  &__content__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    &__img {
      width: 100%;
      max-width: 400px;
      border-radius: 10px;

      @media (max-width: 768px) {
        max-width: 300px;
        width: 100%;
      }

      @media (max-width: 480px) {
        max-width: 100%;
        min-width: 300px;
        height: auto;
      }
    }
  }
  &__content__left {
    flex: 1;
    max-width: 65%;
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;

    @media (max-width: 480px) {
      gap: 10px;
    }

    @media (max-width: 768px) {
      max-width: 100%;
    }

    h3 {
      font-size: 36px;
      color: $color-white;

      @media (max-width: 768px) {
        font-size: 24px;
      }

      @media (max-width: 480px) {
        font-size: 24px;
      }
    }

    span {
      font-size: 20px;
      color: $color-primary;
      font-weight: 600;
      @media (max-width: 768px) {
        font-size: 20px;
      }
      @media (max-width: 480px) {
        font-size: 18px;
      }
    }
    h4 {
      font-size: 20px;
      font-weight: 500;
      color: $color-white;
      @media (max-width: 768px) {
        font-size: 20px;
      }
      @media (max-width: 480px) {
        font-size: 18px;
      }
    }
    p {
      font-size: 20px;
      color: $color-white;
      font-size: 400;

      @media (max-width: 768px) {
        font-size: 20px;
      }

      @media (max-width: 480px) {
        font-size: 18px;
      }
    }

    &__links {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      margin-top: 10px;
      &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;

        & > img {
          width: 30px;
          height: 30px;
        }
        @media (max-width: 480px) {
          & > img {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}
